import React, { useEffect, useState } from "react";
import axios from 'axios';
import Header from "../components/header";
import Footer from "../components/footer";
import download from "../assets/images/download.png";
import cricket from "../assets/images/cricket.png";
import MainSection from "../components/MainSection";
import { useNavigate } from "react-router-dom";
import Modal from 'react-bootstrap/Modal';

const Signuppage = () => {
  const navigate = useNavigate();
  const [fullName, setFullName] = useState("");
  const [emailAddress, setEmailAddress] = useState("");
  const [whatsappNumber, setWhatsappNumber] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [showErrorModal, setShowErrorModal] = useState(false);

  useEffect(() => {
    const user = localStorage.getItem("user");
    if (user) {
      navigate("/profile");
    }
  }, [navigate]);

  const handleCloseErrorModal = () => setShowErrorModal(false);

  const validateInput = () => {
    if (!fullName || !emailAddress || !whatsappNumber || !password) {
      setError('All fields are required.');
      setShowErrorModal(true);
      return false;
    }
    if (!emailAddress.includes('@') || !emailAddress.includes('.')) {
      setError('Invalid email address.');
      setShowErrorModal(true);
      return false;
    }
    if (whatsappNumber.length < 10 || whatsappNumber.length > 15) {
      setError('Whatsapp number must be between 10 and 15 digits.');
      setShowErrorModal(true);
      return false;
    }
    if (password.length < 8) {
      setError('Password must be at least 8 characters long.');
      setShowErrorModal(true);
      return false;
    }
    return true;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateInput()) return;

    setLoading(true);
    setError('');
    const params = new URLSearchParams();
    params.append('user_email', emailAddress);
    params.append('user_whatsapp', whatsappNumber);
    params.append('user_fullname', fullName);
    params.append('user_real_pass', password);
    params.append('table_name', 'users');

    try {
      const source = axios.CancelToken.source();
      const timeout = setTimeout(() => {
        source.cancel();
      }, 20000); // 20 seconds timeout

      const response = await axios.post('https://api.betprowallet.pk/createNewAccUserFromServer.php', params, {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        cancelToken: source.token,
      });

      clearTimeout(timeout);

      console.log('Response data:', response.data);

      if (response.data.status === 'success') {
        localStorage.setItem('user', JSON.stringify(response.data.data));

        console.log('User data:', response.data.data);
        navigate('/home');
      } else {
        setError(response.data.message || 'Sign up failed. Please try again.');
        setShowErrorModal(true);
      }
    } catch (error) {
      if (axios.isCancel(error)) {
        setError('Request timed out. Please try again.');
      } else {
        setError('Please check your internet connection...');
      }
      setShowErrorModal(true);
    } finally {
      setLoading(false);
    }
  };

  const handlebuttonClick = () => {
    navigate('/');
  };

  return (
    <div>
      <Header />
      <div className="register-wrapper2">
        <div className="main-register">
          <div className="container">
            <div className="row align-items-top">
              <div className="col-md-12">
                <div className="left-register-box">
                  <div className="fieldset"  style={{
                                    padding: '10px 0px',
                                  }}>
                    <div className="title mb-5">
                      <h2>Register your Betpro account</h2>
                    </div>
                    <div className="row">
                      <div className="col-lg-12 col-md-12 col-12">
                        <div className="inputs field-container">
                          <form onSubmit={handleSubmit}>
                            <input
                              type="text"
                              className="mb-4"
                              placeholder="Full Name"
                              value={fullName}
                              onChange={(e) => setFullName(e.target.value)}
                            />
                            <input
                              type="text"
                              className="mb-4"
                              placeholder="Email Address"
                              value={emailAddress}
                              onChange={(e) => setEmailAddress(e.target.value)}
                            />
                            <input
                              type="text"
                              className="mb-4"
                              placeholder="Whatsapp Number"
                              value={whatsappNumber}
                              onChange={(e) => setWhatsappNumber(e.target.value)}
                            />
                            <input
                              type="password"
                              className="mb-4"
                              placeholder="Enter password"
                              value={password}
                              onChange={(e) => setPassword(e.target.value)}
                            />
                            <button type="submit" className="btn-signin mb-3" disabled={loading}>
                              {loading ? 'Loading...' : 'Sign up'}
                            </button>
                          </form>
                          <a href="/" className="signup mb-3">
                            Already have an account?
                          </a>
                          <button className="btn-create-account mb-3" onClick={handlebuttonClick}>
                            Sign in
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <MainSection />
      <Footer />
      <Modal show={showErrorModal} onHide={handleCloseErrorModal} centered>
        <Modal.Header closeButton>
          <Modal.Title>Error</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>{error}</p>
        </Modal.Body>
        <Modal.Footer>
          <button variant="secondary" onClick={handleCloseErrorModal}>
            Close
          </button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default Signuppage;
