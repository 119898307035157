import React, { useEffect, useState } from "react";
import Header from "../components/header";
import Nav from "../components/Navigation";
import easypaisa from "../assets/images/deposit/easypaisa.png";
import jazzcash from "../assets/images/deposit/jazzcash.png";
import bank from "../assets/images/deposit/bank.png";
import upaisa from "../assets/images/deposit/upaisa.png";
import nayapay from "../assets/images/deposit/nayapay.png";
import { useNavigate } from "react-router-dom";
import tick from '../assets/images/topTick.svg';
import axios from 'axios';
import Modal from 'react-bootstrap/Modal';
const Withdrawpage = () => {
  const navigate = useNavigate();

  const user = localStorage.getItem("user");
    const parsedUser = JSON.parse(user);
  useEffect(() => {
    

    if (!user) {
      navigate("/");
    } else {
    }
  }, [navigate]);


  const [dialogBackONoFF, setDialogOnOff] = useState(false);




  const options = [
    { value: '1000', label: '1000' },
    { value: '2000', label: '2000' },
    { value: '5000', label: '5000' },
    { value: '10000', label: '10000' },
  ];
  const [amount, setAmount] = useState(0);
  const handleChange = (event) => {
    setAmount(event.target.value);
  };
  const [selectedPrice, setSelectedPrice] = useState('');

  const handleClick = (price) => {
    setSelectedPrice(price);
    setAmount(price);
  };
  useEffect(() => {
  }, [selectedPrice]);

  const paymentOptions = [
    { id: 1, image: easypaisa, alt: 'Easypaisa', text: 'Easypaisa' },
    { id: 2, image: jazzcash, alt: 'JazzCash', text: 'JazzCash' },
    { id: 3, image: bank, alt: 'Bank Transfer', text: 'Bank Transfer' },
  ];
  const [selectedPayment, setSelectedPayment] = useState(null); 

  const handleMethodChange = (optionId) => {
    setSelectedPayment(optionId);
    if(!(optionId === 3)){
        setBankName(null);
    }
  };
  useEffect(() => {
  }, [selectedPayment]);

  const [name, setName] = useState(''); 
  const [number, setNumber] = useState(0); 
  const [bankName, setBankName] = useState(''); 

  const handleNameChange = (event) => {
    setName(event.target.value);
  };

  const handleNumberChange = (event) => {
    const newValue = event.target.value;
    setNumber(newValue);
  };


  const handleBankChange = (event) => {
    const newValue = event.target.value;
    setBankName(newValue);
  };

  const [show, setShow] = useState(false);
  const [Msg, setMsg] = useState('');
  const handleClose = function (){
    setShow(false);

    if(dialogBackONoFF){
    navigate('/');
    }

    setDialogOnOff(false);
  };

  const handleShow = () => setShow(true);
  const handleSubmit = async (e) => {
    e.preventDefault();
    if(!selectedPayment){
      setMsg('Please select a Payment Option to proceed')
      handleShow();
    }
    const selectedPaymentOption = paymentOptions.find(option => option.id === selectedPayment).text;
    
    // const formData = {
    //   amount,
    //   selectedPrice,
    //   selectedPaymentOption,
    //   name,
    //   number,
    //   bankName,
    // };
    // console.log("Form Data:", formData);
 
   if(!(amount >= 500 && amount <= 50000)){
      setMsg('Please write a correct amount to proceed')
      handleShow();
    }
  else if(!number || !name){
    setMsg('please enter valid account details.')
    handleShow();
  }
  else if(!bankName && selectedPayment === 3){
    setMsg('please enter valid account details.')
    handleShow();
  }

  // else if(!number){
  //   setMsg('You have made too many requests recently please wait and try again later.')
  //   handleShow();
  // }
    // else if(){
    //   setMsg('Sorry, you have reached your daily withdrawal limit of Rs.15,000');
    //   handleShow();

    // }else if(){
    //   setMsg('You have already pending withdrawl request please wait');
    //   handleShow();
    // }else if(){
    //   setMsg('You don’t have an active BetPro account. Please deposit funds and activate your Betpro account.');
    //   handleShow();
    //  }
    else{
    // setMsg('Deposit request created successfully.' )

    await checkPendingWithdraw(parsedUser.userID,'withdraw', setMsg, handleShow,selectedPaymentOption);
    }
  };



  // Function to check for pending deposits
const checkPendingWithdraw = async (userName,tableName, setMsg, handleShow,selectedPaymentOption) => {
  try {
   
    
    // Create a cancel token and timeout
    const source = axios.CancelToken.source();
    const timeout = setTimeout(() => {
      source.cancel();
    }, 30000); // 20 seconds timeout

    // Prepare the parameters for the POST request
    const params = new URLSearchParams();
    params.append('user_id', userName);
    params.append('table_name', tableName);

    // Send the POST request to check for pending deposits
    const response = await axios.post(
      'https://api.betprowallet.pk/api_fetch_pending_deposits.php',
      params,
      {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        cancelToken: source.token,
      }
    );

    // Clear the timeout
    clearTimeout(timeout);


    


    if(response.data[response.data.length-1].status == '0'){


      setMsg('You have a withdraw in pending.');
      setDialogOnOff(true);
     
      handleShow();
     
    

    }else {

      

      submitWithdraw(
        'withdraw',
        parsedUser.userID,
        parsedUser.name,
        '1',
        amount,
        name || '',
        number || '',
        bankName || selectedPaymentOption,
        parsedUser.bp_username, // Image URL after upload
        '0',
        parsedUser.number,
        setMsg,
        handleShow
      );

     
    }



  } catch (error) {
    if (axios.isCancel(error)) {
      setMsg('Request timed out. Please try again.');
    } else {
      setMsg('Please check your internet connection.');

    }

    setDialogOnOff(true);

    console.log(error);
    handleShow();
   
  } 
};



    // Function to submit deposit details to the server
    const submitWithdraw = async (
      tableName,
      id,
      username,
      accType,
      amount,
      accTitle,
      accNum,
      bankName,
      betProUsername,
      status,
      whatsapp,
      setMsg,
      handleShow
    ) => {
      try {
        // Prepare the parameters for the POST request
        const params = new URLSearchParams();
        params.append('username', username);
        params.append('user_id', id);
        params.append('acc_type', accType);
        params.append('amount', amount);
        params.append('to', accTitle);
        params.append('acc', accNum);
        params.append('deposit_bank_name', bankName);
        params.append('bet_pro_username', betProUsername);
        params.append('proof', ''); // Image URL
        params.append('status', status);
        params.append('whatsapp', whatsapp);
        params.append('table_name', tableName);
    
        // Create a cancel token and timeout
        const source = axios.CancelToken.source();
        const timeout = setTimeout(() => {
          source.cancel();
        }, 30000); // 30 seconds timeout
    
        // Send the POST request to submit the deposit details
        const response = await axios.post(
          'https://api.betprowallet.pk/pendingUserInsert.php',
          params,
          {
            headers: {
              'Content-Type': 'application/x-www-form-urlencoded',
            },
            cancelToken: source.token,
          }
        );
    
        // Clear the timeout
        clearTimeout(timeout);
    
        // Handle the server's response
        if (response.data.status === 'success') {
          setMsg('Withdraw submitted successfully!');

        } else {
          setMsg('Failed to submit withdraw. Please try again.');
        }
    
        setDialogOnOff(true);
        handleShow();
      } catch (error) {
        if (axios.isCancel(error)) {
          setMsg('Withdraw submission timed out. Please try again.');
        } else {
          setMsg('Failed to submit withdraw. Please check your internet connection.');
        }
        setDialogOnOff(true);
        handleShow();
      }
    };
    

  return (
    <div>
      <Header />

      <div className="main-deposit-wraper">
        <div className="container">
          <div className="middle-depsoit">
            <div className="fieldset-deposit">
              <div className="row">
                <div className="col-12">
                  <h2 className="mt-3 mb-4 text-center">Withdraw funds</h2>
                  <h3 className="hh3 mt-3">Payment Method</h3>
                  <hr />

                  <div className="row mt-3 mb-4">
                  {paymentOptions.map((option) => (
 <div
 key={option.id}
 className="col-4 mt-2 paycardgird"
 onClick={() => handleMethodChange(option.id)} // Make the whole card clickable
 style={{ cursor: 'pointer' }} // Add cursor pointer for full card
>
 <div className="deposit-easypaisa">
   {/* Checkmark, visible only when the payment method is selected */}
   {selectedPayment === option.id && (
     <div className="checkmark">
       <img src={tick} alt="Selected" />
     </div>
   )}
   <label htmlFor={option.id}>
     <input
       type="radio"
       id={option.id}
       name="payment"
       value={option.id}
       checked={selectedPayment === option.id}
       onChange={() => handleMethodChange(option.id)} // Keeps input checked
       style={{ display: 'none' }} // Hide the radio button visually
     />
     <img src={option.image} alt={option.alt} />
   </label>
 </div>
 <div
   className={`deposit-text ${
     selectedPayment === option.id ? 'method-active' : ''
   }`}
 >
   <h5>{option.text}</h5>
 </div>
</div>

))}
                  </div>
                </div>
              </div>


   
              {selectedPayment && (
        <>

              <div className="row">
                <div className="col-6">
                  <div className="amount-detail mt-3">
                    <h3>Amount</h3>
                  </div>
                </div>
                <div className="col-6 ">
                  <div className="amount-detail-number mt-3">
                    <h3>Rs 1000 - Rs 25,000</h3>
                  </div>
                </div>
              </div>
              <hr />

            


              <div className="row mt-3">
                <div className="col-6">
                  <div className="amount-detail mt-3">
                    <p className="wallet-number">
                      Amount (Min 1000 PKR / Max 25,000 PKR)
                    </p>
                  </div>
                </div>
              
                <div className="col-6 ">
                <div className="amount-detail-number mt-3">
             
             <div className="custom-input-wrapper">
             <span className="custom-currency-symbol">Rs.</span>
<input
type="number"
id="amount"
className="custom-amount-input"
placeholder="0.00"
onChange={handleChange}
value={amount}
min="1000"
max="25000"
/>
            </div>
             </div>

                </div>
              </div>

              <div className="row mt-3 mb-3 paycardgird2">
                {options.map((option) => (
                  <div key={option.value} className="col-3">
                    <button
                      key={option.value} 
                      className={`price-btn ${
                        selectedPrice === option.value ? "p-active" : ""
                      }`}
                      onClick={() => handleClick(option.value)}
                    >
                      {option.label}
                    </button>
                  </div>
                ))}
              </div>
              <hr />

              <div className="row mt-3 mb-3">
                <div className="col-6">
                  <div className="amount-detail mt-3">
                    <h3 className="wallet-number">Your Account Name:</h3>
                  </div>
                </div>
                <div className="col-6 ">
                  <div className="amount-detail-number mt-3">
                    <input
                      className="account-btn "
                      type="text"
                      placeholder="Enter your account Name"
                      value={name}
                      onChange={handleNameChange}
                    />
                  </div>
                </div>
              </div>
              <div className="row  mb-3">
                <div className="col-6">
                  <div className="amount-detail mt-4">
                    <h3 className="wallet-number">Your Account Number:</h3>
                  </div>
                </div>
                <div className="col-6 ">
                  <div className="amount-detail-number mt-3">
                    <input
                      className="account-btn "
                      type="number"
                      placeholder="Enter account number"
                      value={number}
                      onChange={handleNumberChange}
                    />
                  </div>
                </div>
              </div>


        
              {selectedPayment === 3 && (
              <div className="row  mb-3">
                <div className="col-6">
                
                  <div className="amount-detail mt-3">
                    <h3 className="wallet-number">Your Bank Name:</h3>
                  </div>
                </div>
                <div className="col-6 ">
                  <div className="amount-detail-number mt-3">
                    <input
                      className="account-btn "
                      type="text"
                      placeholder="Enter Bank Name"
                      value={bankName}
                      onChange={handleBankChange}
                    />
                  </div>
                  </div>
              </div>

              )}

              <div className="row  mb-3">
              <button className="confirm-btn  mt-4 mb-3" onClick={handleSubmit}> Confirm</button>
               </div>

               </>
)}


<div className="row  m-2" >
              
               </div>
           

            </div>


          </div>
          <hr />
        </div>
      </div>
      <Modal show={show} onHide={handleClose}  centered>
        <Modal.Header >
          <Modal.Title>Withdraw Status</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>{Msg}</p>
          <hr />
          <p onClick={handleClose} className="model-ok">Ok</p>
        </Modal.Body>
        
      </Modal>
      <Nav />
    </div>
    
  );
};

export default Withdrawpage;
