import React, { useEffect, useState } from 'react';
import Header from '../components/header';
import Footer from '../components/footer';
import download from '../assets/images/download.png';
import cricket from '../assets/images/cricket.png';
import { FaWhatsapp } from 'react-icons/fa';
import Nav from '../components/Navigation';
import { useNavigate } from "react-router-dom";
import MainSection from "../components/MainSection";
const Homepage = () => {
  const navigate = useNavigate();

  const user = localStorage.getItem('user');
    const parsedUser = JSON.parse(user);
  useEffect(() => {
    
    if (!user) {
      navigate('/');
    } else {     
    }
  }, [navigate]);

  const handleGoToWebsite= ()=>
    {
      console.log("handle Go To Bet Pro website Button");
    }

  const handledepositclick=()=>{
    navigate('/deposit')
  }
  const handlewithdrawclick=()=>{
    navigate('/withdraw')
  }
  return (
    <div>
      <Header />
      <div className="login-wrapper2">
        <div className="main-login">
          <div className="container">
       

              <div className="row align-items-top">
                <div className="col-md-12">
                  <div className="left-login-box1">
                    <div className="fieldset">
                      <div className="title mb-5">


                        <h2 className="mt-5">Active Betpro account</h2>


                        
       {parsedUser.userActive == 1 && (
        <>

                        <div className="account3 mt-3">
                        <div className="whatappp mt-3 ">
                        <div className="btn-active-account2">
                          <h5>Username: <span style={{marginLeft:'4px'}}> {parsedUser.bp_username}</span>
                        </h5></div>
                        </div>
                        <div className="whatappp ">
                        <div  className="btn-active-account2">
                          <h5>Password:   <span style={{marginLeft:'4px'}}>{parsedUser.bp_pass}</span></h5></div>
                        </div>
                        <div className="whatappp mt-3">
                        <div  className="btn-active-website2">
                          <h5 onClick={handleGoToWebsite}>Go to BetPro website</h5></div>
                        </div>
                        </div>

                        </>)}

                        {parsedUser.userActive == 0 && (
        <>
                        <div className="account mt-3">
                          <h3 className="text-center  mb-3"> ضروری ہدایات</h3>

                          
                          <ul>
                            <li className="mb-3">  ڈپازٹ کرنے کے بعد آپ کا Betpro اکاؤنٹ ایکٹیو ہو جائے گا۔</li>
                            <li className="mb-3"> <>Betpro کا username اور password </>یہاں پر ظاہر ہوگا۔</li>
                            <li className="mb-3">ڈپازٹ کی گئی رقم آپ کی Betpro اکاؤنٹ میں شامل کی جائے گی۔ </li>
                          </ul>
                        </div>

                        </>)}

                        <div className="withdraw mt-4 mb-3">
                        <button className="cus-btn1 btn btn-default "  onClick={handledepositclick}>
                          <h5>
                            <span className=""></span>+Deposit
                          </h5>
                        </button>
                        <button  className="cus-btn1 btn btn-default" onClick={handlewithdrawclick}>
                          <h5>
                            <span className=""></span>Withdraw
                          </h5>
                        </button>
                        </div>

                        <div className="whatappp  mb-3 text-center">
                        <button className="btn-whatappp"><h5><FaWhatsapp style={{ color: 'green', fontSize: '1.5em',marginRight:'3px' }} />Contact on Whatapp</h5></button>
                        </div>
                
                      </div>
                   

                    </div>
                  </div>
                </div>
              </div>
            
          </div>
        </div>
      </div>


      <MainSection />
  
        <Nav/>
    </div>
  )
}

export default Homepage;
